export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAILURE = 'GET_BALANCE_FAILURE';

export const GET_MAIN_ACCOUNT_BALANCES_REQUEST = 'GET_MAIN_ACCOUNT_BALANCES_REQUEST';
export const GET_MAIN_ACCOUNT_BALANCES_SUCCESS = 'GET_MAIN_ACCOUNT_BALANCES_SUCCESS';
export const GET_MAIN_ACCOUNT_BALANCES_FAILURE = 'GET_MAIN_ACCOUNT_BALANCES_FAILURE';

export const GET_PROFIT_ACCOUNT_BALANCES_REQUEST = 'GET_PROFIT_ACCOUNT_BALANCES_REQUEST';
export const GET_PROFIT_ACCOUNT_BALANCES_SUCCESS = 'GET_PROFIT_ACCOUNT_BALANCES_SUCCESS';
export const GET_PROFIT_ACCOUNT_BALANCES_FAILURE = 'GET_PROFIT_ACCOUNT_BALANCES_FAILURE';

export const GET_XLM_TO_USDT_CONVERSION_RATE_REQUEST = 'GET_XLM_TO_USDT_CONVERSION_RATE_REQUEST';
export const GET_XLM_TO_USDT_CONVERSION_RATE_SUCCESS = 'GET_XLM_TO_USDT_CONVERSION_RATE_SUCCESS';
export const GET_XLM_TO_USDT_CONVERSION_RATE_FAILURE = 'GET_XLM_TO_USDT_CONVERSION_RATE_FAILURE';