import React, { Component } from 'react';
import firebase from 'firebase';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Router from './modules/router/Router';
import ReduxStore, { history } from './modules/store';
import {getLanguage} from './modules/actions/appActions';
import './App.scss';
import firebaseConfig from './firebase.json';

const initialState = {};
const store = ReduxStore(initialState);

class App extends Component {
  componentWillMount () {
    this.initializeFirebase();
    this.getLanguage();
  }

  render () {

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={this.renderLoading()}>
            <Router/>
          </React.Suspense>
        </ConnectedRouter>
      </Provider>
    );
  }

  renderLoading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"/>
    </div>
  )

  getLanguage = () => {
    store.dispatch(getLanguage());
  }

  initializeFirebase = () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

}

export default App;
