import {
	GET_APP_FEES_REQUEST,
	GET_APP_FEES_SUCCESS,
	GET_APP_FEES_FAILURE,
	UPDATE_APP_FEES_FAILURE,
	UPDATE_APP_FEES_REQUEST,
	UPDATE_APP_FEES_SUCCESS,
	GET_APP_LIMITS_FAILURE,
	GET_APP_LIMITS_REQUEST,
	GET_APP_LIMITS_SUCCESS,
	UPDATE_APP_LIMITS_FAILURE,
	UPDATE_APP_LIMITS_REQUEST,
	UPDATE_APP_LIMITS_SUCCESS,
	GET_GLOBAL_PERMISSIONS_FAILURE,
	GET_GLOBAL_PERMISSIONS_REQUEST,
	GET_GLOBAL_PERMISSIONS_SUCCESS,
	UPDATE_GLOBAL_PERMISSIONS_FAILURE,
	UPDATE_GLOBAL_PERMISSIONS_REQUEST,
	UPDATE_GLOBAL_PERMISSIONS_SUCCESS,
	GET_MAINTENANCE_MODE_FAILURE,
	GET_MAINTENANCE_MODE_REQUEST,
	GET_MAINTENANCE_MODE_SUCCESS,
	UPDATE_MAINTENANCE_MODE_FAILURE,
	UPDATE_MAINTENANCE_MODE_REQUEST,
	UPDATE_MAINTENANCE_MODE_SUCCESS,
	GET_ADMINS_DEPARTMENTS_FAILURE,
	GET_ADMINS_DEPARTMENTS_REQUEST,
	GET_ADMINS_DEPARTMENTS_SUCCESS,
	UPDATE_ADMINS_DEPARTMENTS_FAILURE,
	UPDATE_ADMINS_DEPARTMENTS_REQUEST,
	UPDATE_ADMINS_DEPARTMENTS_SUCCESS,
	UPLOAD_ADMINS_DEPARTMENTS_PHOTO_FAILURE,
	UPLOAD_ADMINS_DEPARTMENTS_PHOTO_REQUEST,
	UPLOAD_ADMINS_DEPARTMENTS_PHOTO_SUCCESS,
} from '../types/settingsTypes';
import * as AUTH_TYPES from '../types/authTypes';

const INITIAL_STATE = {
	error: '',
	loading: false,
	fees: null,
	limits: null,
	permissions: null,
	maintenanceMode: null,
	adminDepartments: null
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_APP_FEES_REQUEST:
			return {...state, loading: true};
		case GET_APP_FEES_SUCCESS:
			return {...state, loading: false, fees: action.payload};
		case GET_APP_FEES_FAILURE:
			return {...state, loading: false, error: action.error};

		case UPDATE_APP_FEES_REQUEST:
			return {...state, loading: true};
		case UPDATE_APP_FEES_SUCCESS:
			return {...state, loading: false, fees: action.payload};
		case UPDATE_APP_FEES_FAILURE:
			return {...state, loading: false, error: action.error};

		case GET_APP_LIMITS_REQUEST:
			return {...state, loading: true};
		case GET_APP_LIMITS_SUCCESS:
			return {...state, loading: false, limits: action.payload};
		case GET_APP_LIMITS_FAILURE:
			return {...state, loading: false, error: action.error};

		case UPDATE_APP_LIMITS_REQUEST:
			return {...state, loading: true};
		case UPDATE_APP_LIMITS_SUCCESS:
			return {...state, loading: false, limits: action.payload};
		case UPDATE_APP_LIMITS_FAILURE:
			return {...state, loading: false, error: action.error};

		case GET_GLOBAL_PERMISSIONS_REQUEST:
			return {...state, loading: true};
		case GET_GLOBAL_PERMISSIONS_SUCCESS:
			return {...state, loading: false, permissions: action.payload};
		case GET_GLOBAL_PERMISSIONS_FAILURE:
			return {...state, loading: false, error: action.error};

		case UPDATE_GLOBAL_PERMISSIONS_REQUEST:
			return {...state, loading: true};
		case UPDATE_GLOBAL_PERMISSIONS_SUCCESS:
			return {...state, loading: false, permissions: action.payload};
		case UPDATE_GLOBAL_PERMISSIONS_FAILURE:
			return {...state, loading: false, error: action.error};

		case GET_MAINTENANCE_MODE_REQUEST:
			return {...state, loading: true};
		case GET_MAINTENANCE_MODE_SUCCESS:
			return {...state, loading: false, maintenanceMode: action.payload};
		case GET_MAINTENANCE_MODE_FAILURE:
			return {...state, loading: false, error: action.error};

		case UPDATE_MAINTENANCE_MODE_REQUEST:
			return {...state, loading: true};
		case UPDATE_MAINTENANCE_MODE_SUCCESS:
			return {...state, loading: false, maintenanceMode: action.payload};
		case UPDATE_MAINTENANCE_MODE_FAILURE:
			return {...state, loading: false, error: action.error};

		case GET_ADMINS_DEPARTMENTS_REQUEST:
			return {...state, loading: true};
		case GET_ADMINS_DEPARTMENTS_SUCCESS:
			return {...state, loading: false, adminDepartments: action.payload};
		case GET_ADMINS_DEPARTMENTS_FAILURE:
			return {...state, loading: false, error: action.error};

		case UPDATE_ADMINS_DEPARTMENTS_REQUEST:
			return {...state, loading: true};
		case UPDATE_ADMINS_DEPARTMENTS_SUCCESS:
			return {...state, loading: false, adminDepartments: action.payload};
		case UPDATE_ADMINS_DEPARTMENTS_FAILURE:
			return {...state, loading: false, error: action.error};

		case UPLOAD_ADMINS_DEPARTMENTS_PHOTO_REQUEST:
			return {...state, loading: true};
		case UPLOAD_ADMINS_DEPARTMENTS_PHOTO_SUCCESS:
			return {...state, loading: false, adminDepartments: action.payload};
		case UPLOAD_ADMINS_DEPARTMENTS_PHOTO_FAILURE:
			return {...state, loading: false, error: action.error};

		case AUTH_TYPES.SIGN_OUT_SUCCESS:
		case AUTH_TYPES.SET_INITIAL_STATE:
			return {...INITIAL_STATE};

		default:
			return state;
	}
};

export default reducer;