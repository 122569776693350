import { userTypes } from "@mindbush/artomos-shared-repo/schemas";
import {
  GET_ADMINS_REQUEST,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_SUCCESS_UPDATING_SELECTED_ADMIN,
  GET_ADMINS_FAILURE,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILURE,
  CLEAR_SELECTED_ADMIN,
  SELECT_ADMIN,
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_ACCOUNT_STATUS_FAILURE,
  UPDATE_ADMIN_ACCOUNT_STATUS_REQUEST,
  UPDATE_ADMIN_ACCOUNT_STATUS_SUCCESS,
} from "../types/adminsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const admin = { ...userTypes };

const admins = {
  strict: true,
  type: ["null", "array"],
  items: admin,
};

const adminsSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    adminList: admins,
    selectedAdmin: admin,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  adminList: null,
  selectedAdmin: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  // todo: fix user schema!

  switch (action.type) {
    case GET_ADMINS_REQUEST:
      return { ...state, loading: true, error: "" };
    case GET_ADMINS_SUCCESS:
      return { ...state, loading: false, adminList: action.payload, error: "" };
    case GET_ADMINS_SUCCESS_UPDATING_SELECTED_ADMIN:
      return {
        ...state,
        loading: false,
        adminList: action.adminList,
        selectedAdmin: action.selectedAdmin,
        error: "",
      };
    case GET_ADMINS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_ADMIN_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAdmin: action.payload,
        error: "",
      };
    case UPDATE_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SELECT_ADMIN:
      return {
        ...state,
        loading: false,
        selectedAdmin: action.payload,
        error: "",
      };
    case CLEAR_SELECTED_ADMIN:
      return { ...state, loading: false, selectedAdmin: null, error: "" };

    case CREATE_ADMIN_REQUEST:
      return { ...state, loading: true, error: "" };
    case CREATE_ADMIN_SUCCESS:
      return { ...state, loading: false, error: "" };
    case CREATE_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_ADMIN_ACCOUNT_STATUS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_ADMIN_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAdmin: action.payload,
        error: "",
      };
    case UPDATE_ADMIN_ACCOUNT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
