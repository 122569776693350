import { toast } from "react-toastify";
import {
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_REQUEST,
  IS_APP_LOADING,
  RENDER_TOASTER,
} from "../types/appTypes";
import { adminHistoryUpdateTypes } from "@mindbush/artomos-shared-repo/enums";
import { TOAST_TYPE_ENUMS } from "../../utility/toastEnums";
import englishStrings from "../../strings/en.json";

export const getLanguage = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_LANGUAGE_REQUEST });
    return new Promise(async (resolve) => {
      const { appActions_noStringsAvailable } = getState().app.strings;
      if (englishStrings) {
        dispatch({
          type: GET_LANGUAGE_SUCCESS,
          payload: englishStrings,
        });
        resolve();
      } else {
        dispatch({
          type: GET_LANGUAGE_FAILURE,
          error: appActions_noStringsAvailable,
        });
        resolve();
      }
    });
  };
};

export const getAdminHistoryDescText = (desc) => (dispatch, getState) => {
  const {
    USER_PROFILE,
    BUSINESS_PROFILE,
    ACCOUNT_STATUS,
    BANK_ACCOUNTS,
    PERMISSIONS,
    UPLOADED_DOCS,
    ADMIN_NOTES_CREATED,
    ADMIN_NOTES_UPDATE,
    GLOBAL_FEES,
    GLOBAL_LIMITS,
    GLOBAL_PERMISSIONS,
    GLOBAL_MAINTENANCE_MODE,
    GLOBAL_DEPARTMENT_CONFIG,
    NEW_ADMIN_CREATION,
  } = adminHistoryUpdateTypes;

  const {
    global_userProfileUpdate,
    global_businessProfileUpdate,
    global_accountStatusUpdate,
    global_bankAccountsUpdate,
    global_permissionsUpdate,
    global_uploadedDocsUpdate,
    global_adminNotesUpdate,
    global_adminNotesCreated,
    global_globalFeesUpdate,
    global_globalLimitsUpdate,
    global_globalPermissionsUpdate,
    global_globalMaintenanceModeUpdate,
    global_globalDepartmentConfigUpdate,
    global_newAdminCreation,
  } = getState().app.strings;

  let descText = "";

  switch (desc) {
    case USER_PROFILE:
      descText = global_userProfileUpdate;
      break;
    case BUSINESS_PROFILE:
      descText = global_businessProfileUpdate;
      break;
    case ACCOUNT_STATUS:
      descText = global_accountStatusUpdate;
      break;
    case BANK_ACCOUNTS:
      descText = global_bankAccountsUpdate;
      break;
    case PERMISSIONS:
      descText = global_permissionsUpdate;
      break;
    case UPLOADED_DOCS:
      descText = global_uploadedDocsUpdate;
      break;
    case GLOBAL_FEES:
      descText = global_globalFeesUpdate;
      break;
    case GLOBAL_LIMITS:
      descText = global_globalLimitsUpdate;
      break;
    case GLOBAL_PERMISSIONS:
      descText = global_globalPermissionsUpdate;
      break;
    case GLOBAL_MAINTENANCE_MODE:
      descText = global_globalMaintenanceModeUpdate;
      break;
    case GLOBAL_DEPARTMENT_CONFIG:
      descText = global_globalDepartmentConfigUpdate;
      break;
    case ADMIN_NOTES_CREATED:
      descText = global_adminNotesCreated;
      break;
    case ADMIN_NOTES_UPDATE:
      descText = global_adminNotesUpdate;
      break;
    case NEW_ADMIN_CREATION:
      descText = global_newAdminCreation;
      break;

    default:
      descText = global_userProfileUpdate;
  }

  return descText;
};

export const renderToaster = (toastType, message) => (dispatch) => {
  dispatch({ type: RENDER_TOASTER });
  return new Promise((resolve) => {
    const { SUCCESS, INFO, WARNING, ERROR } = TOAST_TYPE_ENUMS;

    let renderToaster;

    switch (toastType) {
      case SUCCESS:
        renderToaster = toast[SUCCESS](message);
        break;
      case INFO:
        renderToaster = toast[INFO](message);
        break;
      case WARNING:
        renderToaster = toast[WARNING](message);
        break;
      case ERROR:
        renderToaster = toast[ERROR](message);
        break;

      default:
        renderToaster = toast[SUCCESS](message);
    }

    resolve();
    return renderToaster;
  });
};

export const isAppLoading = () => (dispatch, getState) => {
  const { loading: loadingAccounts } = getState().accounts;
  const { loading: loadingAdmins } = getState().admins;
  const { loading: loadingAgents } = getState().agents;
  const { loading: loadingTransactions } = getState().transactions;
  const { loading: loadingStats } = getState().stats;
  const { loading: loadingUsers } = getState().users;
  const { loading: loadingAuth } = getState().auth;

  const isAppLoading =
    loadingAccounts ||
    loadingAdmins ||
    loadingAgents ||
    loadingTransactions ||
    loadingStats ||
    loadingUsers ||
    loadingAuth;
  dispatch({
    type: IS_APP_LOADING,
    payload: isAppLoading,
  });
};
