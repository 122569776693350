//import utils from '@mindbush/artomos-shared-repo/utils';
import { userTypes } from "@mindbush/artomos-shared-repo/schemas";
import {
  UPDATE_AGENT_FAILURE,
  UPDATE_AGENT_REQUEST,
  UPDATE_AGENT_SUCCESS,
  SELECT_AGENT,
  CLEAR_SELECTED_AGENT,
  GET_AGENTS_FAILURE,
  GET_AGENTS_REQUEST,
  GET_AGENTS_SUCCESS,
  GET_AGENTS_SUCCESS_UPDATING_SELECTED_AGENT,
  UPDATE_AGENT_BANK_ACCOUNT_STATUS_FAILURE,
  UPDATE_AGENT_BANK_ACCOUNT_STATUS_REQUEST,
  UPDATE_AGENT_BANK_ACCOUNT_STATUS_SUCCESS,
  UPDATE_AGENT_PERMISSIONS_FAILURE,
  UPDATE_AGENT_PERMISSIONS_REQUEST,
  UPDATE_AGENT_PERMISSIONS_SUCCESS,
  UPDATE_AGENT_ACCOUNT_STATUS_FAILURE,
  UPDATE_AGENT_ACCOUNT_STATUS_REQUEST,
  UPDATE_AGENT_ACCOUNT_STATUS_SUCCESS,
  DELETE_AGENT_IMAGE_FAILURE,
  DELETE_AGENT_IMAGE_REQUEST,
  DELETE_AGENT_IMAGE_SUCCESS,
} from "../types/agentsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const agent = { ...userTypes };

const agents = {
  strict: true,
  type: ["null", "array"],
  items: agent,
};

const agentSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    agentList: agents,
    selectedAgent: agent,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  agentList: null,
  selectedAgent: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  // todo: fix user schema!
  //utils.validateReduxSchema(usersSchema, state);

  switch (action.type) {
    case GET_AGENTS_REQUEST:
      return { ...state, loading: true, error: "" };
    case GET_AGENTS_SUCCESS:
      return { ...state, loading: false, agentList: action.payload, error: "" };
    case GET_AGENTS_SUCCESS_UPDATING_SELECTED_AGENT:
      return {
        ...state,
        loading: false,
        agentList: action.agentList,
        selectedAgent: action.selectedAgent,
        error: "",
      };
    case GET_AGENTS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_AGENT_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAgent: action.payload,
        error: "",
      };
    case UPDATE_AGENT_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_AGENT_BANK_ACCOUNT_STATUS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_AGENT_BANK_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAgent: action.payload,
        error: "",
      };
    case UPDATE_AGENT_BANK_ACCOUNT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_AGENT_PERMISSIONS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_AGENT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAgent: action.payload,
        error: "",
      };
    case UPDATE_AGENT_PERMISSIONS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SELECT_AGENT:
      return {
        ...state,
        loading: false,
        selectedAgent: action.payload,
        error: "",
      };
    case CLEAR_SELECTED_AGENT:
      return { ...state, loading: false, selectedAgent: null, error: "" };

    case UPDATE_AGENT_ACCOUNT_STATUS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_AGENT_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAgent: action.payload,
        error: "",
      };
    case UPDATE_AGENT_ACCOUNT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case DELETE_AGENT_IMAGE_REQUEST:
      return { ...state, loading: true, error: "" };
    case DELETE_AGENT_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAgent: action.payload,
        error: "",
      };
    case DELETE_AGENT_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
