import utils from "@mindbush/artomos-shared-repo/utils";
import { adminNotesTypes } from "@mindbush/artomos-shared-repo/schemas";
import {
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CLEAR_SELECTED_NOTE,
  SELECT_NOTE,
  UPDATE_NOTE_FAILURE,
  UPDATE_NOTE_REQUEST,
  UPDATE_NOTE_SUCCESS,
} from "../types/notesTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const notesSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    selectedNote: { ...adminNotesTypes },
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  selectedNote: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(notesSchema, state);

  switch (action.type) {
    case CREATE_NOTE_REQUEST:
      return { ...state, loading: true };
    case CREATE_NOTE_SUCCESS:
      return { ...state, loading: false };
    case CREATE_NOTE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SELECT_NOTE:
      return { ...state, loading: false, selectedNote: action.payload };
    case CLEAR_SELECTED_NOTE:
      return { ...state, loading: false, selectedNote: null };

    case UPDATE_NOTE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_NOTE_SUCCESS:
      return { ...state, loading: false, selectedNote: action.payload };
    case UPDATE_NOTE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
