import utils from "@mindbush/artomos-shared-repo/utils";
import { adminHistoryTypes } from "@mindbush/artomos-shared-repo/schemas";
import {
  UPDATE_ADMIN_HISTORY_REQUEST,
  UPDATE_ADMIN_HISTORY_SUCCESS,
  UPDATE_ADMIN_HISTORY_FAILURE,
  SELECT_ADMIN_HISTORY,
  CLEAR_SELECTED_ADMIN_HISTORY,
  UPDATE_SETTINGS_ADMIN_HISTORY_FAILURE,
  UPDATE_SETTINGS_ADMIN_HISTORY_REQUEST,
  UPDATE_SETTINGS_ADMIN_HISTORY_SUCCESS,
} from "../types/historyTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const historySchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...adminHistoryTypes.properties },
};

const historyStateSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    selectedHistory: historySchema,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  selectedHistory: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(historyStateSchema, state);

  switch (action.type) {
    case UPDATE_ADMIN_HISTORY_REQUEST:
      return { ...state, loading: true };
    case UPDATE_ADMIN_HISTORY_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_ADMIN_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_SETTINGS_ADMIN_HISTORY_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SETTINGS_ADMIN_HISTORY_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_SETTINGS_ADMIN_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SELECT_ADMIN_HISTORY:
      return { ...state, loading: true, selectedHistory: action.payload };
    case CLEAR_SELECTED_ADMIN_HISTORY:
      return { ...state, loading: false, selectedHistory: null };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
