//import utils from '@mindbush/artomos-shared-repo/utils';
import { userTypes } from "@mindbush/artomos-shared-repo/schemas";
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS_UPDATING_SELECTED_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_BANK_ACCOUNT_STATUS_REQUEST,
  UPDATE_USER_BANK_ACCOUNT_STATUS_SUCCESS,
  UPDATE_USER_BANK_ACCOUNT_STATUS_FAILURE,
  UPDATE_USER_PERMISSIONS_REQUEST,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_PERMISSIONS_FAILURE,
  CLEAR_SELECTED_USER,
  SELECT_USER,
  UPDATE_USER_ACCOUNT_STATUS_FAILURE,
  UPDATE_USER_ACCOUNT_STATUS_REQUEST,
  UPDATE_USER_ACCOUNT_STATUS_SUCCESS,
  DELETE_USER_IMAGE_FAILURE,
  DELETE_USER_IMAGE_REQUEST,
  DELETE_USER_IMAGE_SUCCESS,
} from "../types/usersTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const user = { ...userTypes };

const users = {
  strict: true,
  type: ["null", "array"],
  items: user,
};

const usersSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    userList: users,
    selectedUser: user,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  userList: null,
  selectedUser: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  // todo: fix user schema!
  //utils.validateReduxSchema(usersSchema, state);

  switch (action.type) {
    case GET_USERS_REQUEST:
      return { ...state, loading: true, error: "" };
    case GET_USERS_SUCCESS:
      return { ...state, loading: false, userList: action.payload, error: "" };
    case GET_USERS_SUCCESS_UPDATING_SELECTED_USER:
      return {
        ...state,
        loading: false,
        userList: action.userList,
        selectedUser: action.selectedUser,
        error: "",
      };
    case GET_USERS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
        error: "",
      };
    case UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_USER_BANK_ACCOUNT_STATUS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_USER_BANK_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
        error: "",
      };
    case UPDATE_USER_BANK_ACCOUNT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_USER_PERMISSIONS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
        error: "",
      };
    case UPDATE_USER_PERMISSIONS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SELECT_USER:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
        error: "",
      };
    case CLEAR_SELECTED_USER:
      return { ...state, loading: false, selectedUser: null, error: "" };

    case UPDATE_USER_ACCOUNT_STATUS_REQUEST:
      return { ...state, loading: true, error: "" };
    case UPDATE_USER_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
        error: "",
      };
    case UPDATE_USER_ACCOUNT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case DELETE_USER_IMAGE_REQUEST:
      return { ...state, loading: true, error: "" };
    case DELETE_USER_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
        error: "",
      };
    case DELETE_USER_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
