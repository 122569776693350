import utils from "@mindbush/artomos-shared-repo/utils";
import {
  GET_BALANCE_FAILURE,
  GET_BALANCE_REQUEST,
  GET_BALANCE_SUCCESS,
  GET_MAIN_ACCOUNT_BALANCES_SUCCESS,
  GET_MAIN_ACCOUNT_BALANCES_REQUEST,
  GET_MAIN_ACCOUNT_BALANCES_FAILURE,
  GET_PROFIT_ACCOUNT_BALANCES_FAILURE,
  GET_PROFIT_ACCOUNT_BALANCES_REQUEST,
  GET_PROFIT_ACCOUNT_BALANCES_SUCCESS,
  GET_XLM_TO_USDT_CONVERSION_RATE_FAILURE,
  GET_XLM_TO_USDT_CONVERSION_RATE_REQUEST,
  GET_XLM_TO_USDT_CONVERSION_RATE_SUCCESS,
} from "../types/accountsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const accountsStateSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    currentBalance: { type: "number" },
    mainAccountBalances: { type: ["array", "null"] },
    profitAccountBalances: { type: ["array", "null"] },
    xlmToUsdtConversionRate: { type: "number" },
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: true,
  currentBalance: 0,
  mainAccountBalances: null,
  profitAccountBalances: null,
  xlmToUsdtConversionRate: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(accountsStateSchema, state);

  switch (action.type) {
    case GET_BALANCE_REQUEST:
      return { ...state, loading: true };
    case GET_BALANCE_SUCCESS:
      return { ...state, loading: false, currentBalance: action.payload };
    case GET_BALANCE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_MAIN_ACCOUNT_BALANCES_REQUEST:
      return { ...state, loading: true };
    case GET_MAIN_ACCOUNT_BALANCES_SUCCESS:
      return { ...state, loading: false, mainAccountBalances: action.payload };
    case GET_MAIN_ACCOUNT_BALANCES_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_PROFIT_ACCOUNT_BALANCES_REQUEST:
      return { ...state, loading: true };
    case GET_PROFIT_ACCOUNT_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        profitAccountBalances: action.payload,
      };
    case GET_PROFIT_ACCOUNT_BALANCES_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_XLM_TO_USDT_CONVERSION_RATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_XLM_TO_USDT_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        xlmToUsdtConversionRate: action.payload,
      };
    case GET_XLM_TO_USDT_CONVERSION_RATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
