import utils from "@mindbush/artomos-shared-repo/utils";
import { ratingsHistoryTypes } from "@mindbush/artomos-shared-repo/schemas";
import { SELECT_RATING, CLEAR_SELECTED_RATING } from "../types/ratingsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const selectedRatingSchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...ratingsHistoryTypes.items.properties },
};

const ratingsStateSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    selectedRating: selectedRatingSchema,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  selectedRating: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(ratingsStateSchema, state);

  switch (action.type) {
    case SELECT_RATING:
      return { ...state, loading: false, selectedRating: action.payload };
    case CLEAR_SELECTED_RATING:
      return { ...state, loading: false, selectedRating: null };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
