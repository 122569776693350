export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_SUCCESS_UPDATING_SELECTED_ADMIN = 'GET_ADMINS_SUCCESS_UPDATING_SELECTED_ADMIN';
export const GET_ADMINS_FAILURE = 'GET_ADMINS_FAILURE';

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';

export const SELECT_ADMIN = 'SELECT_ADMIN';
export const CLEAR_SELECTED_ADMIN = 'CLEAR_SELECTED_ADMIN';

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';

export const UPDATE_ADMIN_ACCOUNT_STATUS_REQUEST = 'UPDATE_ADMIN_ACCOUNT_STATUS_REQUEST';
export const UPDATE_ADMIN_ACCOUNT_STATUS_SUCCESS = 'UPDATE_ADMIN_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_ADMIN_ACCOUNT_STATUS_FAILURE = 'UPDATE_ADMIN_ACCOUNT_STATUS_FAILURE';