import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import admins from './adminsReducers';
import agents from './agentsReducers';
import app from './appReducers';
import auth from './authReducer';
import accounts from './accountsReducers';
import adminHistory from './historyReducers';
import transactions from './transactionsReducers';
import news from './newsReducers';
import notes from './notesReducers';
import ratings from './ratingsReducers';
import settings from './settingsReducers';
import stats from './statsReducers';
import users from './userssReducers';

const createRootReducer = (history) => combineReducers({
	admins,
	agents,
	app,
	auth,
	accounts,
	history: adminHistory,
	transactions,
	news,
	notes,
	ratings,
	settings,
	stats,
	users,
	router: connectRouter(history),
});

export default createRootReducer;
