export const GET_APP_FEES_REQUEST = 'GET_APP_FEES_REQUEST';
export const GET_APP_FEES_SUCCESS = 'GET_APP_FEES_SUCCESS';
export const GET_APP_FEES_FAILURE = 'GET_APP_FEES_FAILURE';

export const UPDATE_APP_FEES_REQUEST = 'UPDATE_APP_FEES_REQUEST';
export const UPDATE_APP_FEES_SUCCESS = 'UPDATE_APP_FEES_SUCCESS';
export const UPDATE_APP_FEES_FAILURE = 'UPDATE_APP_FEES_FAILURE';

export const GET_APP_LIMITS_REQUEST = 'GET_APP_LIMITS_REQUEST';
export const GET_APP_LIMITS_SUCCESS = 'GET_APP_LIMITS_SUCCESS';
export const GET_APP_LIMITS_FAILURE = 'GET_APP_LIMITS_FAILURE';

export const UPDATE_APP_LIMITS_REQUEST = 'UPDATE_APP_LIMITS_REQUEST';
export const UPDATE_APP_LIMITS_SUCCESS = 'UPDATE_APP_LIMITS_SUCCESS';
export const UPDATE_APP_LIMITS_FAILURE = 'UPDATE_APP_LIMITS_FAILURE';

export const GET_GLOBAL_PERMISSIONS_REQUEST = 'GET_GLOBAL_PERMISSIONS_REQUEST';
export const GET_GLOBAL_PERMISSIONS_SUCCESS = 'GET_GLOBAL_PERMISSIONS_SUCCESS';
export const GET_GLOBAL_PERMISSIONS_FAILURE = 'GET_GLOBAL_PERMISSIONS_FAILURE';

export const UPDATE_GLOBAL_PERMISSIONS_REQUEST = 'UPDATE_GLOBAL_PERMISSIONS_REQUEST';
export const UPDATE_GLOBAL_PERMISSIONS_SUCCESS = 'UPDATE_GLOBAL_PERMISSIONS_SUCCESS';
export const UPDATE_GLOBAL_PERMISSIONS_FAILURE = 'UPDATE_GLOBAL_PERMISSIONS_FAILURE';

export const GET_MAINTENANCE_MODE_REQUEST = 'GET_MAINTENANCE_MODE_REQUEST';
export const GET_MAINTENANCE_MODE_SUCCESS = 'GET_MAINTENANCE_MODE_SUCCESS';
export const GET_MAINTENANCE_MODE_FAILURE = 'GET_MAINTENANCE_MODE_FAILURE';

export const UPDATE_MAINTENANCE_MODE_REQUEST = 'UPDATE_MAINTENANCE_MODE_REQUEST';
export const UPDATE_MAINTENANCE_MODE_SUCCESS = 'UPDATE_MAINTENANCE_MODE_SUCCESS';
export const UPDATE_MAINTENANCE_MODE_FAILURE = 'UPDATE_MAINTENANCE_MODE_FAILURE';

export const GET_ADMINS_DEPARTMENTS_REQUEST = 'GET_ADMINS_DEPARTMENTS_REQUEST';
export const GET_ADMINS_DEPARTMENTS_SUCCESS = 'GET_ADMINS_DEPARTMENTS_SUCCESS';
export const GET_ADMINS_DEPARTMENTS_FAILURE = 'GET_ADMINS_DEPARTMENTS_FAILURE';

export const UPDATE_ADMINS_DEPARTMENTS_REQUEST = 'UPDATE_ADMINS_DEPARTMENTS_REQUEST';
export const UPDATE_ADMINS_DEPARTMENTS_SUCCESS = 'UPDATE_ADMINS_DEPARTMENTS_SUCCESS';
export const UPDATE_ADMINS_DEPARTMENTS_FAILURE = 'UPDATE_ADMINS_DEPARTMENTS_FAILURE';

export const UPLOAD_ADMINS_DEPARTMENTS_PHOTO_REQUEST = 'UPLOAD_ADMINS_DEPARTMENTS_PHOTO_REQUEST';
export const UPLOAD_ADMINS_DEPARTMENTS_PHOTO_SUCCESS = 'UPLOAD_ADMINS_DEPARTMENTS_PHOTO_SUCCESS';
export const UPLOAD_ADMINS_DEPARTMENTS_PHOTO_FAILURE = 'UPLOAD_ADMINS_DEPARTMENTS_PHOTO_FAILURE';