import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, Redirect} from 'react-router';
import {connect} from 'react-redux';

// Containers
const DefaultLayout = React.lazy(() => import('../../containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('../../views/Pages/Login'));
const Register = React.lazy(() => import('../../views/Pages/Register'));
const Page404 = React.lazy(() => import('../../views/Pages/Page404'));
const Page500 = React.lazy(() => import('../../views/Pages/Page500'));

class RouterComponent extends Component {

	static propTypes = {
		auth: PropTypes.shape({
			user: PropTypes.object
		})
	};

	render() {
		const privateRouteProps = {
			component: DefaultLayout,
			path: '/',
		};
		return (
			<Switch>
				<Route exact path="/login" name="Login Page" render={props => <Login {...props} />}/>
				<Route path="/register" name="Register Page" render={props => <Register {...props} />}/>
				<Route path="/404" name="Page 404" render={props => <Page404 {...props} />}/>
				<Route path="/500" name="Page 500" render={props => <Page500 {...props} />}/>
				{this.renderPrivateRoute(privateRouteProps)}
			</Switch>
		);
	}

	renderPrivateRoute = ({component: Component, ...rest}) => {
		return (
			<Route
				{...rest}
				render={props => this.handleRoute(props, Component)}
			/>
		);
	};

	handleRoute = (props, Component) => {
		const isAuthenticated = this.props.auth.user;

		return isAuthenticated ? (
			<Component {...props} />
		) : (
			<Redirect
				to={{
					pathname: '/login',
					state: {from: props.location}
				}}
			/>
		);
	};
}

const mapStateToProps = ({auth}) => ({
	auth
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(RouterComponent);
