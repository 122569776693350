import {createStore, applyMiddleware, compose} from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

const store = (initialState = {}) =>{
	const middlewares = [ReduxThunk, routerMiddleware(history)];
	const enhancers = [applyMiddleware(...middlewares)];

	const composeEnhancers = process.env.NODE_ENV !== 'production'
	&& typeof window === 'object'
	&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			shouldHotReload: false
		})
		: compose;

	return createStore(
		createRootReducer(history),
		initialState,
		composeEnhancers(...enhancers)
	);
};

export default store;
