export const GET_AGENTS_REQUEST = 'GET_AGENTS_REQUEST';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_AGENTS_SUCCESS_UPDATING_SELECTED_AGENT = 'GET_AGENTS_SUCCESS_UPDATING_SELECTED_AGENT';
export const GET_AGENTS_FAILURE = 'GET_AGENTS_FAILURE';

export const UPDATE_AGENT_BANK_ACCOUNT_STATUS_REQUEST = 'UPDATE_BANK_ACCOUNT_STATUS_AGENT_REQUEST';
export const UPDATE_AGENT_BANK_ACCOUNT_STATUS_SUCCESS = 'UPDATE_BANK_ACCOUNT_STATUS_AGENT_SUCCESS';
export const UPDATE_AGENT_BANK_ACCOUNT_STATUS_FAILURE = 'UPDATE_BANK_ACCOUNT_STATUS_AGENT_FAILURE';

export const UPDATE_AGENT_PERMISSIONS_REQUEST = 'UPDATE_AGENT_PERMISSIONS_REQUEST';
export const UPDATE_AGENT_PERMISSIONS_SUCCESS = 'UPDATE_AGENT_PERMISSIONS_SUCCESS';
export const UPDATE_AGENT_PERMISSIONS_FAILURE = 'UPDATE_AGENT_PERMISSIONS_FAILURE';

export const UPDATE_AGENT_REQUEST = 'UPDATE_AGENT_REQUEST';
export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_FAILURE = 'UPDATE_AGENT_FAILURE';

export const SELECT_AGENT = 'SELECT_AGENT';
export const CLEAR_SELECTED_AGENT = 'CLEAR_SELECTED_AGENT';

export const UPDATE_AGENT_ACCOUNT_STATUS_REQUEST = 'UPDATE_AGENT_ACCOUNT_STATUS_REQUEST';
export const UPDATE_AGENT_ACCOUNT_STATUS_SUCCESS = 'UPDATE_AGENT_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_AGENT_ACCOUNT_STATUS_FAILURE = 'UPDATE_AGENT_ACCOUNT_STATUS_FAILURE';

export const DELETE_AGENT_IMAGE_REQUEST = 'DELETE_AGENT_IMAGE_REQUEST';
export const DELETE_AGENT_IMAGE_SUCCESS = 'DELETE_AGENT_IMAGE_SUCCESS';
export const DELETE_AGENT_IMAGE_FAILURE = 'DELETE_AGENT_IMAGE_FAILURE';