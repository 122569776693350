export const GET_TRANSACTION_REQUEST = 'GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_SUCCESS_INITIALIZE = 'GET_TRANSACTION_SUCCESS_INITIALIZE';
export const GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE';

export const GET_PAGINATED_TRANSACTION_REQUEST = 'GET_PAGINATED_TRANSACTION_REQUEST';
export const GET_PAGINATED_TRANSACTION_SUCCESS = 'GET_PAGINATED_TRANSACTION_SUCCESS';
export const GET_PAGINATED_TRANSACTION_FAILURE = 'GET_PAGINATED_TRANSACTION_FAILURE';

export const GET_DEBIT_REQUEST_HISTORY_REQUEST = 'GET_DEBIT_REQUEST_HISTORY_REQUEST';
export const GET_DEBIT_REQUEST_HISTORY_SUCCESS = 'GET_DEBIT_REQUEST_HISTORY_SUCCESS';
export const GET_DEBIT_REQUEST_HISTORY_FAILURE = 'GET_DEBIT_REQUEST_HISTORY_FAILURE';

export const GET_DEPOSIT_REQUEST_ITEM_REQUEST = 'GET_DEPOSIT_REQUEST_ITEM_REQUEST';
export const GET_DEPOSIT_REQUEST_ITEM_SUCCESS = 'GET_DEPOSIT_REQUEST_ITEM_SUCCESS';
export const GET_DEPOSIT_REQUEST_ITEM_FAILURE = 'GET_DEPOSIT_REQUEST_ITEM_FAILURE';

export const CREATE_PURCHASE_FROM_WALLET_REQUEST = 'CREATE_PURCHASE_FROM_WALLET_REQUEST';
export const CREATE_PURCHASE_FROM_WALLET_SUCCESS = 'CREATE_PURCHASE_FROM_WALLET_SUCCESS';
export const CREATE_PURCHASE_FROM_WALLET_FAILURE = 'CREATE_PURCHASE_FROM_WALLET_FAILURE';

export const UPDATE_PURCHASE_REQUEST_ITEM_REQUEST = 'UPDATE_PURCHASE_REQUEST_ITEM_REQUEST';
export const UPDATE_PURCHASE_REQUEST_ITEM_SUCCESS = 'UPDATE_PURCHASE_REQUEST_ITEM_SUCCESS';
export const UPDATE_PURCHASE_REQUEST_ITEM_FAILURE = 'UPDATE_PURCHASE_REQUEST_ITEM_FAILURE';

export const GET_PURCHASE_REQUEST_ITEM_REQUEST = 'GET_PURCHASE_REQUEST_ITEM_REQUEST';
export const GET_PURCHASE_REQUEST_ITEM_SUCCESS = 'GET_PURCHASE_REQUEST_ITEM_SUCCESS';
export const GET_PURCHASE_REQUEST_ITEM_FAILURE = 'GET_PURCHASE_REQUEST_ITEM_FAILURE';
export const GET_PURCHASE_REQUEST_ITEM_NO_PURCHASE_REQUEST_ITEM = 'GET_PURCHASE_REQUEST_ITEM_NO_PURCHASE_REQUEST_ITEM';

export const CREATE_DEBIT_REQUEST_ITEM_REQUEST = 'CREATE_DEBIT_REQUEST_ITEM_REQUEST';
export const CREATE_DEBIT_REQUEST_ITEM_SUCCESS = 'CREATE_DEBIT_REQUEST_ITEM_SUCCESS';
export const CREATE_DEBIT_REQUEST_ITEM_FAILURE = 'CREATE_DEBIT_REQUEST_ITEM_FAILURE';

export const CLEAR_DEBIT_REQUEST_HISTORY_REQUEST = 'CLEAR_DEBIT_REQUEST_HISTORY_REQUEST';
export const CLEAR_DEBIT_REQUEST_HISTORY_SUCCESS = 'CLEAR_DEBIT_REQUEST_HISTORY_SUCCESS';
export const CLEAR_DEBIT_REQUEST_HISTORY_FAILURE = 'CLEAR_DEBIT_REQUEST_HISTORY_FAILURE';

export const NEW_TRANSACTION_REQUEST = 'NEW_TRANSACTION_REQUEST';
export const NEW_TRANSACTION_SUCCESS = 'NEW_TRANSACTION_SUCCESS';
export const NEW_TRANSACTION_FAILURE = 'NEW_TRANSACTION_FAILURE';

export const SELECTED_TRANSACTION_REQUEST = 'SELECTED_TRANSACTION_REQUEST';
export const SELECTED_TRANSACTION_SUCCESS = 'SELECTED_TRANSACTION_SUCCESS';
export const SELECTED_TRANSACTION_FAILURE = 'SELECTED_TRANSACTION_FAILURE';

export const NEW_RECIPIENT_TRANSACTION_REQUEST = 'NEW_RECIPIENT_TRANSACTION_REQUEST';
export const NEW_RECIPIENT_TRANSACTION_SUCCESS = 'NEW_RECIPIENT_TRANSACTION_SUCCESS';
export const NEW_RECIPIENT_TRANSACTION_FAILURE = 'NEW_RECIPIENT_TRANSACTION_FAILURE';

export const SEND_FUNDS_REQUEST = 'SEND_FUNDS_REQUEST';
export const SEND_FUNDS_SUCCESS = 'SEND_FUNDS_SUCCESS';
export const SEND_FUNDS_FAILURE = 'SEND_FUNDS_FAILURE';

export const CALC_SEND_FUNDS_REQUEST = 'CALC_SEND_FUNDS_REQUEST';
export const CALC_SEND_FUNDS_SUCCESS = 'CALC_SEND_FUNDS_SUCCESS';
export const CALC_SEND_FUNDS_FAILURE = 'CALC_SEND_FUNDS_FAILURE';

export const SEND_PUSH_NOTIF_REQUEST = 'SEND_PUSH_NOTIF_REQUEST';
export const SEND_PUSH_NOTIF_SUCCESS = 'SEND_PUSH_NOTIF_SUCCESS';
export const SEND_PUSH_NOTIF_FAILURE = 'SEND_PUSH_NOTIF_FAILURE';

export const ADD_FUNDS_REQUEST = 'ADD_FUNDS_REQUEST';
export const ADD_FUNDS_SUCCESS = 'ADD_FUNDS_SUCCESS';
export const ADD_FUNDS_FAILURE = 'ADD_FUNDS_FAILURE';

export const HANDLE_SEND_TRANSACTION_ERRORS_REQUEST = 'HANDLE_SEND_TRANSACTION_ERRORS_REQUEST';
export const HANDLE_SEND_TRANSACTION_ERRORS_SUCCESS = 'HANDLE_SEND_TRANSACTION_ERRORS_SUCCESS';
export const HANDLE_SEND_TRANSACTION_ERRORS_FAILURE = 'HANDLE_SEND_TRANSACTION_ERRORS_FAILURE';

export const HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_REQUEST = 'HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_REQUEST';
export const HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_SUCCESS = 'HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_SUCCESS';
export const HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_FAILURE = 'HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_FAILURE';

export const HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_REQUEST = 'HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_REQUEST';
export const HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_SUCCESS = 'HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_SUCCESS';
export const HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_FAILURE = 'HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_FAILURE';

export const HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_REQUEST = 'HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_REQUEST';
export const HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_SUCCESS = 'HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_SUCCESS';
export const HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_FAILURE = 'HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_FAILURE';

export const CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_REQUEST = 'CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_REQUEST';
export const CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_SUCCESS = 'CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_SUCCESS';
export const CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_FAILURE = 'CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_FAILURE';

export const CALC_ADD_FUNDS_FROM_BANK_TRANSFER_REQUEST = 'CALC_ADD_FUNDS_FROM_BANK_TRANSFER_REQUEST';
export const CALC_ADD_FUNDS_FROM_BANK_TRANSFER_SUCCESS = 'CALC_ADD_FUNDS_FROM_BANK_TRANSFER_SUCCESS';
export const CALC_ADD_FUNDS_FROM_BANK_TRANSFER_FAILURE = 'CALC_ADD_FUNDS_FROM_BANK_TRANSFER_FAILURE';

export const CALC_WITHDRAW_FUNDS_VIA_AGENT_REQUEST = 'CALC_WITHDRAW_FUNDS_VIA_AGENT_REQUEST';
export const CALC_WITHDRAW_FUNDS_VIA_AGENT_SUCCESS = 'CALC_WITHDRAW_FUNDS_VIA_AGENT_SUCCESS';
export const CALC_WITHDRAW_FUNDS_VIA_AGENT_FAILURE = 'CALC_WITHDRAW_FUNDS_VIA_AGENT_FAILURE';

export const CALC_ADD_FUNDS_VIA_AGENT_REQUEST = 'CALC_ADD_FUNDS_VIA_AGENT_REQUEST';
export const CALC_ADD_FUNDS_VIA_AGENT_SUCCESS = 'CALC_ADD_FUNDS_VIA_AGENT_SUCCESS';
export const CALC_ADD_FUNDS_VIA_AGENT_FAILURE = 'CALC_ADD_FUNDS_VIA_AGENT_FAILURE';

export const CREATE_DEPOSIT_REQUEST_ITEM_REQUEST = 'CREATE_DEPOSIT_REQUEST_ITEM_REQUEST';
export const CREATE_DEPOSIT_REQUEST_ITEM_SUCCESS = 'CREATE_DEPOSIT_REQUEST_ITEM_SUCCESS';
export const CREATE_DEPOSIT_REQUEST_ITEM_FAILURE = 'CREATE_DEPOSIT_REQUEST_ITEM_FAILURE';

export const CLEAR_DEPOSIT_REQUEST_ITEM_REQUEST = 'CLEAR_DEPOSIT_REQUEST_ITEM_REQUEST';
export const CLEAR_DEPOSIT_REQUEST_ITEM_SUCCESS = 'CLEAR_DEPOSIT_REQUEST_ITEM_SUCCESS';
export const CLEAR_DEPOSIT_REQUEST_ITEM_FAILURE = 'CLEAR_DEPOSIT_REQUEST_ITEM_FAILURE';

export const UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST = 'UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST';
export const UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS = 'UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS';
export const UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE = 'UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE';

export const UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST = 'UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST';
export const UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS = 'UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS';
export const UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE = 'UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE';

export const SELECT_TRANSACTION = 'SELECT_TRANSACTION';
export const CLEAR_SELECTED_TRANSACTION = 'CLEAR_SELECTED_TRANSACTION';