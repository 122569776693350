import utils from "@mindbush/artomos-shared-repo/utils";
import { transactionItemTypes } from "@mindbush/artomos-shared-repo/schemas";
import {
  ADD_FUNDS_FAILURE,
  ADD_FUNDS_REQUEST,
  ADD_FUNDS_SUCCESS,
  CALC_ADD_FUNDS_FROM_BANK_TRANSFER_FAILURE,
  CALC_ADD_FUNDS_FROM_BANK_TRANSFER_REQUEST,
  CALC_ADD_FUNDS_FROM_BANK_TRANSFER_SUCCESS,
  CALC_WITHDRAW_FUNDS_VIA_AGENT_FAILURE,
  CALC_WITHDRAW_FUNDS_VIA_AGENT_REQUEST,
  CALC_WITHDRAW_FUNDS_VIA_AGENT_SUCCESS,
  CALC_ADD_FUNDS_VIA_AGENT_FAILURE,
  CALC_ADD_FUNDS_VIA_AGENT_SUCCESS,
  CALC_ADD_FUNDS_VIA_AGENT_REQUEST,
  CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_FAILURE,
  CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_REQUEST,
  CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_SUCCESS,
  CREATE_DEBIT_REQUEST_ITEM_FAILURE,
  CREATE_DEBIT_REQUEST_ITEM_REQUEST,
  CREATE_DEBIT_REQUEST_ITEM_SUCCESS,
  GET_TRANSACTION_FAILURE,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_PAGINATED_TRANSACTION_FAILURE,
  GET_PAGINATED_TRANSACTION_REQUEST,
  GET_PAGINATED_TRANSACTION_SUCCESS,
  GET_TRANSACTION_SUCCESS_INITIALIZE,
  GET_DEBIT_REQUEST_HISTORY_FAILURE,
  GET_DEBIT_REQUEST_HISTORY_REQUEST,
  GET_DEBIT_REQUEST_HISTORY_SUCCESS,
  CREATE_PURCHASE_FROM_WALLET_FAILURE,
  CREATE_PURCHASE_FROM_WALLET_REQUEST,
  CREATE_PURCHASE_FROM_WALLET_SUCCESS,
  GET_PURCHASE_REQUEST_ITEM_SUCCESS,
  GET_PURCHASE_REQUEST_ITEM_FAILURE,
  GET_PURCHASE_REQUEST_ITEM_REQUEST,
  GET_PURCHASE_REQUEST_ITEM_NO_PURCHASE_REQUEST_ITEM,
  UPDATE_PURCHASE_REQUEST_ITEM_FAILURE,
  UPDATE_PURCHASE_REQUEST_ITEM_REQUEST,
  UPDATE_PURCHASE_REQUEST_ITEM_SUCCESS,
  HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_FAILURE,
  HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_REQUEST,
  HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_SUCCESS,
  HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_FAILURE,
  HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_REQUEST,
  HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_SUCCESS,
  HANDLE_SEND_TRANSACTION_ERRORS_FAILURE,
  HANDLE_SEND_TRANSACTION_ERRORS_REQUEST,
  HANDLE_SEND_TRANSACTION_ERRORS_SUCCESS,
  HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_FAILURE,
  HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_REQUEST,
  HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_SUCCESS,
  NEW_RECIPIENT_TRANSACTION_FAILURE,
  NEW_RECIPIENT_TRANSACTION_REQUEST,
  NEW_RECIPIENT_TRANSACTION_SUCCESS,
  NEW_TRANSACTION_FAILURE,
  NEW_TRANSACTION_REQUEST,
  NEW_TRANSACTION_SUCCESS,
  SELECTED_TRANSACTION_FAILURE,
  SELECTED_TRANSACTION_REQUEST,
  SELECTED_TRANSACTION_SUCCESS,
  SEND_FUNDS_FAILURE,
  SEND_FUNDS_REQUEST,
  SEND_FUNDS_SUCCESS,
  CALC_SEND_FUNDS_FAILURE,
  CALC_SEND_FUNDS_REQUEST,
  CALC_SEND_FUNDS_SUCCESS,
  SEND_PUSH_NOTIF_FAILURE,
  SEND_PUSH_NOTIF_REQUEST,
  SEND_PUSH_NOTIF_SUCCESS,
  CLEAR_DEBIT_REQUEST_HISTORY_REQUEST,
  CLEAR_DEBIT_REQUEST_HISTORY_SUCCESS,
  CLEAR_DEBIT_REQUEST_HISTORY_FAILURE,
  CREATE_DEPOSIT_REQUEST_ITEM_REQUEST,
  CREATE_DEPOSIT_REQUEST_ITEM_FAILURE,
  CREATE_DEPOSIT_REQUEST_ITEM_SUCCESS,
  CLEAR_DEPOSIT_REQUEST_ITEM_FAILURE,
  CLEAR_DEPOSIT_REQUEST_ITEM_REQUEST,
  CLEAR_DEPOSIT_REQUEST_ITEM_SUCCESS,
  GET_DEPOSIT_REQUEST_ITEM_FAILURE,
  GET_DEPOSIT_REQUEST_ITEM_REQUEST,
  GET_DEPOSIT_REQUEST_ITEM_SUCCESS,
  UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE,
  UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST,
  UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS,
  UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE,
  UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST,
  UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS,
  SELECT_TRANSACTION,
  CLEAR_SELECTED_TRANSACTION,
} from "../types/transactionsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const transactionListSchema = {
  type: "array",
  optional: true,
  items: {
    type: "object",
    properties: { ...transactionItemTypes },
  },
};

const transactionSchema = {
  type: ["object", "null"],
  optional: true,
  properties: { ...transactionItemTypes },
};

const serverResponseSchema = {
  type: ["null", "string", "object"],
  optional: true,
  properties: {
    amount: { type: "number" },
  },
};

const recentTransactionSchema = {
  type: ["array", "object"],
  optional: true,
  items: [
    {
      type: "object",
      properties: { ...transactionItemTypes },
    },
    {
      type: "array",
      items: {
        type: "object",
        properties: { ...transactionItemTypes },
      },
    },
  ],
};

const agentTransactionDetailsSchema = {
  type: "object",
  optional: true,
  properties: {
    ratingByAgent: { type: "number" },
    agentCancelledReason: { type: "string" },
    agentCompletedTimestamp: { type: "string" },
    agentCancelledTimestamp: { type: "string" },
    agentScannedTimestamp: { type: "string" },
    ratingByCustomer: { type: "number" },
    agentTransactionProgress: {
      type: "array",
      items: { type: "string" },
    },
  },
};

const debitRequestHistorySchema = {
  type: ["null", "object"],
  optional: true,
  properties: {
    id: { type: "string" },
    generatedDescription: { type: "string" },
    amountChargedTotal: { type: "number" },
    transactionToFullName: { type: "string" },
    transactionFromEmail: { type: "string" },
    amount: { type: "number" },
    transactionToUid: { type: "string" },
    ledgerBalance: { type: "number" },
    amountToPrincipal: { type: "number" },
    transactionToEmail: { type: "string" },
    amountChargedFeePlatform: { type: "number" },
    amountChargedFeeAgent: { type: "number" },
    amountReceiving: { type: "number" },
    transactionType: { type: "string" },
    transactionFromUid: { type: "string" },
    transactionFromFullName: { type: "string" },
    amountChargedFeeTotal: { type: "number" },
    transactionDate: { type: "string" },
    agentTransactionDetails: agentTransactionDetailsSchema,
  },
};

const notificationSentSchema = {
  type: "object",
  optional: true,
  properties: {
    appId: { type: "string" },
    message: { type: "string" },
    transaction: {
      type: "object",
      properties: {
        amount: { type: "number" },
        amountToPrincipal: { type: "number" },
        transactionFromFullName: { type: "string" },
        transactionFromEmail: { type: "string" },
        transactionFromUid: { type: "string" },
        transactionToFullName: { type: "string" },
        transactionToEmail: { type: "string" },
        transactionToUid: { type: "string" },
      },
    },
  },
};

const transactionsStateSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    loadingPaginatedList: { type: "boolean" },
    initialized: { type: "boolean" },
    transactionList: transactionListSchema,
    paginatedList: transactionListSchema,
    depositRequestItem: transactionSchema,
    purchaseRequestItem: transactionSchema,
    selectedTransaction: transactionSchema,
    serverResponse: serverResponseSchema,
    handleTransactionErrorLoading: { type: "boolean", optional: true },
    sendFundsLoading: { type: "boolean", optional: true },
    purchaseRequestLoading: { type: "boolean", optional: true },
    recentTransaction: recentTransactionSchema,
    notificationSent: notificationSentSchema,
    debitRequestHistory: debitRequestHistorySchema,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: true,
  loadingPaginatedList: true,
  purchaseRequestLoading: false,
  initialized: false,
  debitRequestHistory: null,
  depositRequestItem: null,
  purchaseRequestItem: null,
  serverResponse: null,
  selectedTransaction: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(transactionsStateSchema, state);

  switch (action.type) {
    case GET_TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case GET_TRANSACTION_SUCCESS_INITIALIZE:
      return { ...state, loading: false, initialized: true };
    case GET_TRANSACTION_SUCCESS:
      return { ...state, loading: false, transactionList: action.payload };
    case GET_TRANSACTION_FAILURE:
      return { ...state, loading: false };

    case GET_PAGINATED_TRANSACTION_REQUEST:
      return { ...state, loadingPaginatedList: true };
    case GET_PAGINATED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingPaginatedList: false,
        paginatedList: action.payload,
      };
    case GET_PAGINATED_TRANSACTION_FAILURE:
      return { ...state, loadingPaginatedList: false };

    case GET_DEBIT_REQUEST_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEBIT_REQUEST_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        debitRequestHistory: action.payload,
      };
    case GET_DEBIT_REQUEST_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CLEAR_DEBIT_REQUEST_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_DEBIT_REQUEST_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        debitRequestHistory: null,
      };
    case CLEAR_DEBIT_REQUEST_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case NEW_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionList: action.payload,
      };
    case NEW_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SELECTED_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELECTED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedTransaction: action.payload,
      };
    case SELECTED_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case NEW_RECIPIENT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case NEW_RECIPIENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEW_RECIPIENT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SEND_PUSH_NOTIF_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case SEND_PUSH_NOTIF_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationSent: action.payload,
      };
    case SEND_PUSH_NOTIF_FAILURE:
      return {
        ...state,
        loading: false,
        notificationError: action.error,
      };

    case SEND_FUNDS_REQUEST:
      return {
        ...state,
        loading: true,
        sendFundsLoading: true,
      };
    case SEND_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        sendFundsLoading: false,
        recentTransaction: action.payload,
      };
    case SEND_FUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        sendFundsLoading: false,
        error: action.error,
      };

    case CALC_SEND_FUNDS_REQUEST:
      return {
        ...state,
        loading: true,
        calcFundsLoading: true,
      };
    case CALC_SEND_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        calcSendFunds: action.payload,
      };
    case CALC_SEND_FUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        error: action.error,
      };

    case HANDLE_SEND_TRANSACTION_ERRORS_REQUEST:
      return {
        ...state,
        loading: true,
        handleTransactionErrorLoading: true,
      };
    case HANDLE_SEND_TRANSACTION_ERRORS_SUCCESS:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        recentTransaction: action.payload,
      };
    case HANDLE_SEND_TRANSACTION_ERRORS_FAILURE:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        error: action.error,
      };

    case HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_REQUEST:
      return {
        ...state,
        loading: true,
        handleTransactionErrorLoading: true,
      };
    case HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_SUCCESS:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        serverResponse: action.payload,
      };
    case HANDLE_WITHDRAWAL_TRANSACTION_ERRORS_FAILURE:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        error: action.error,
      };

    case HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_REQUEST:
      return {
        ...state,
        loading: true,
        handleTransactionErrorLoading: true,
      };
    case HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_SUCCESS:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        serverResponse: action.payload,
      };
    case HANDLE_ADD_FROM_BANK_TRANSFER_TRANSACTION_ERRORS_FAILURE:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        error: action.error,
      };

    case HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_REQUEST:
      return {
        ...state,
        loading: true,
        handleTransactionErrorLoading: true,
      };
    case HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_SUCCESS:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        serverResponse: action.payload,
      };
    case HANDLE_ADD_FUNDS_VIA_AGENT_TRANSACTION_ERRORS_FAILURE:
      return {
        ...state,
        loading: false,
        handleTransactionErrorLoading: false,
        error: action.error,
      };

    case CREATE_DEBIT_REQUEST_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DEBIT_REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        serverResponse: action.payload,
      };
    case CREATE_DEBIT_REQUEST_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case ADD_FUNDS_REQUEST:
      return {
        ...state,
        loading: true,
        addFundsLoading: true,
      };
    case ADD_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        addFundsLoading: false,
        recentTransaction: action.response,
        transactionList: action.payload,
      };
    case ADD_FUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        addFundsLoading: false,
        error: action.error,
      };

    case CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
        addFundsLoading: true,
      };
    case CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        addFundsLoading: false,
        addFromBankTransferResp: action.payload,
      };
    case CHARGE_ADD_FUNDS_VIA_BANK_TRANSFER_FAILURE:
      return {
        ...state,
        loading: false,
        addFundsLoading: false,
        error: action.error,
      };

    case CALC_ADD_FUNDS_FROM_BANK_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
        calcFundsLoading: true,
      };
    case CALC_ADD_FUNDS_FROM_BANK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        calcAddFromBankTransfer: action.payload,
      };
    case CALC_ADD_FUNDS_FROM_BANK_TRANSFER_FAILURE:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        error: action.error,
      };

    case CALC_WITHDRAW_FUNDS_VIA_AGENT_REQUEST:
      return {
        ...state,
        loading: true,
        calcFundsLoading: true,
      };
    case CALC_WITHDRAW_FUNDS_VIA_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        calcWithdrawViaAgent: action.payload,
      };
    case CALC_WITHDRAW_FUNDS_VIA_AGENT_FAILURE:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        error: action.error,
      };

    case CALC_ADD_FUNDS_VIA_AGENT_REQUEST:
      return {
        ...state,
        loading: true,
        calcFundsLoading: true,
      };
    case CALC_ADD_FUNDS_VIA_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        calcAddFundsViaAgent: action.payload,
      };
    case CALC_ADD_FUNDS_VIA_AGENT_FAILURE:
      return {
        ...state,
        loading: false,
        calcFundsLoading: false,
        error: action.error,
      };

    case CREATE_DEPOSIT_REQUEST_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DEPOSIT_REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        depositRequestItem: action.payload,
      };
    case CREATE_DEPOSIT_REQUEST_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GET_DEPOSIT_REQUEST_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEPOSIT_REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        depositRequestItem: action.payload,
      };
    case GET_DEPOSIT_REQUEST_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CLEAR_DEPOSIT_REQUEST_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_DEPOSIT_REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        depositRequestItem: null,
      };
    case CLEAR_DEPOSIT_REQUEST_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CREATE_PURCHASE_FROM_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PURCHASE_FROM_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PURCHASE_FROM_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GET_PURCHASE_REQUEST_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PURCHASE_REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseRequestItem: action.payload,
      };
    case GET_PURCHASE_REQUEST_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_PURCHASE_REQUEST_ITEM_NO_PURCHASE_REQUEST_ITEM:
      return {
        ...state,
        purchaseRequestItem: null,
        loading: false,
      };

    case UPDATE_PURCHASE_REQUEST_ITEM_REQUEST:
      return {
        ...state,
        purchaseRequestLoading: true,
      };
    case UPDATE_PURCHASE_REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        purchaseRequestLoading: false,
        purchaseRequestItem: action.payload,
      };
    case UPDATE_PURCHASE_REQUEST_ITEM_FAILURE:
      return {
        ...state,
        purchaseRequestLoading: false,
        error: action.error,
      };

    case UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST:
      return {
        ...state,
        purchaseRequestLoading: true,
      };
    case UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS:
      return {
        ...state,
        purchaseRequestLoading: false,
      };
    case UPDATE_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE:
      return {
        ...state,
        purchaseRequestLoading: false,
        error: action.error,
      };

    case UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_DB_TRANSACTIONS_CUSTOMER_CLOSED_MODAL_TIMESTAMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SELECT_TRANSACTION:
      return {
        ...state,
        loading: false,
        selectedTransaction: action.payload,
        error: "",
      };
    case CLEAR_SELECTED_TRANSACTION:
      return { ...state, loading: false, selectedTransaction: null, error: "" };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
