export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_NOT_SIGNED_IN = 'SIGN_IN_NOT_SIGNED_IN';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const STORE_AUTH_TOKEN = 'STORE_AUTH_TOKEN';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const GET_ADMIN_ROLE_VALUE_PENDING = 'GET_ADMIN_ROLE_VALUE_PENDING';
export const GET_ADMIN_ROLE_VALUE_SUCCESS = 'GET_ADMIN_ROLE_VALUE_SUCCESS';
export const GET_ADMIN_ROLE_VALUE_FAILURE = 'GET_ADMIN_ROLE_VALUE_FAILURE';
