import utils from "@mindbush/artomos-shared-repo/utils";
import {
  SUBMIT_NEWS_FOR_TRANSLATION_FAILURE,
  SUBMIT_NEWS_FOR_TRANSLATION_REQUEST,
  SUBMIT_NEWS_FOR_TRANSLATION_SUCCESS,
} from "../types/newsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const newsStateSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    recentlySubmittedMessages: { type: ["null", "array"] },
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  recentlySubmittedMessages: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(newsStateSchema, state);

  switch (action.type) {
    case SUBMIT_NEWS_FOR_TRANSLATION_REQUEST:
      return { ...state, loading: true };
    case SUBMIT_NEWS_FOR_TRANSLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        recentlySubmittedMessages: action.payload,
      };
    case SUBMIT_NEWS_FOR_TRANSLATION_FAILURE:
      return { ...state, loading: false, error: action.error };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
