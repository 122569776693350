import * as AUTH_TYPES from '../types/authTypes';

const {HOST_URL} = process.env;

const INITIAL_STATE = {
  error: '',
  loading: false,
  user: null,
  hostUrl: HOST_URL,
  authToken: '',
  adminRoleValue: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_TYPES.SIGN_UP_REQUEST:
      return {...state, ...INITIAL_STATE, loading: true};
    case AUTH_TYPES.SIGN_UP_SUCCESS:
      return {...state, ...INITIAL_STATE, user: action.payload};
    case AUTH_TYPES.SIGN_UP_FAILURE:
      return {...state, ...INITIAL_STATE, loading: false, error: action.payload};


    case AUTH_TYPES.SIGN_IN_REQUEST:
      return {...state, ...INITIAL_STATE, loading: true};
    case AUTH_TYPES.SIGN_IN_SUCCESS:
      return {...state, ...INITIAL_STATE, user: action.payload};
    case AUTH_TYPES.SIGN_IN_NOT_SIGNED_IN:
      return {...state, ...INITIAL_STATE, loading: false};
    case AUTH_TYPES.SIGN_IN_FAILURE:
      return {...state, ...INITIAL_STATE, loading: false, error: action.payload};

    case AUTH_TYPES.STORE_AUTH_TOKEN:
      return {...state, authToken: action.payload};

    case AUTH_TYPES.GET_ADMIN_ROLE_VALUE_PENDING:
      return {...state, loading: true};
    case AUTH_TYPES.GET_ADMIN_ROLE_VALUE_SUCCESS:
      return {...state, loading: false, adminRoleValue: action.payload};
    case AUTH_TYPES.GET_ADMIN_ROLE_VALUE_FAILURE:
      return {...state, loading: false, error: action.payload};

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return {...INITIAL_STATE};
    default:
      return state;
  }
};

export default reducer;
