export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_SUCCESS_UPDATING_SELECTED_USER = 'GET_USERS_SUCCESS_UPDATING_SELECTED_USER';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const SELECT_USER = 'SELECT_USER';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';

export const UPDATE_USER_BANK_ACCOUNT_STATUS_REQUEST = 'UPDATE_BANK_ACCOUNT_STATUS_USER_REQUEST';
export const UPDATE_USER_BANK_ACCOUNT_STATUS_SUCCESS = 'UPDATE_BANK_ACCOUNT_STATUS_USER_SUCCESS';
export const UPDATE_USER_BANK_ACCOUNT_STATUS_FAILURE = 'UPDATE_BANK_ACCOUNT_STATUS_USER_FAILURE';

export const UPDATE_USER_PERMISSIONS_REQUEST = 'UPDATE_USER_PERMISSIONS_REQUEST';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_FAILURE = 'UPDATE_USER_PERMISSIONS_FAILURE';

export const UPDATE_USER_ACCOUNT_STATUS_REQUEST = 'UPDATE_USER_ACCOUNT_STATUS_REQUEST';
export const UPDATE_USER_ACCOUNT_STATUS_SUCCESS = 'UPDATE_USER_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_USER_ACCOUNT_STATUS_FAILURE = 'UPDATE_USER_ACCOUNT_STATUS_FAILURE';

export const DELETE_USER_IMAGE_REQUEST = 'DELETE_USER_IMAGE_REQUEST';
export const DELETE_USER_IMAGE_SUCCESS = 'DELETE_USER_IMAGE_SUCCESS';
export const DELETE_USER_IMAGE_FAILURE = 'DELETE_USER_IMAGE_FAILURE';