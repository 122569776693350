import {
	GET_LANGUAGE_REQUEST,
	GET_LANGUAGE_SUCCESS,
	GET_LANGUAGE_FAILURE,
	IS_APP_LOADING,
} from '../types/appTypes';
import englishStrings from '../../strings/en.json';
import * as AUTH_TYPES from '../types/authTypes';

const INITIAL_STATE = {
	error: '',
	strings: englishStrings,
	loading: false,
	globalLoading: true,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_LANGUAGE_REQUEST:
			return {...state, ...INITIAL_STATE, loading: true};
		case GET_LANGUAGE_SUCCESS:
			return {...state, ...INITIAL_STATE, loading: false, strings: action.payload};
		case GET_LANGUAGE_FAILURE:
			return {...state, ...INITIAL_STATE, loading: false, error: action.error};

		case IS_APP_LOADING:
			return {...state, globalLoading: action.payload};

		case AUTH_TYPES.SIGN_OUT_SUCCESS:
		case AUTH_TYPES.SET_INITIAL_STATE:
			return {...INITIAL_STATE};

		default:
			return state;
	}
};

export default reducer;